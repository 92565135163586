import React from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "./Pages/Dashboard";
import Nopage from "./Pages/Nopage";
import Internet from "./Pages/Internet";
import Sync from "./Pages/Sync";

function App() {
  return (
    <Routes>
      <Route path="*" exact={true} element={<Nopage />} />
      {/* {navigator.onLine ? <Dashboard /> : <Internet />} */}
      {!navigator.onLine && <Internet />}
      <Route exact path="/sync" element={<Sync />} />
      <Route exact path="/" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
