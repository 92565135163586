import { createSlice } from "@reduxjs/toolkit";
// import * as actionNames from './actionTypes';

let lastId = 0;

export const dashboardSlice = createSlice({
  name: "dashboardState",
  initialState: {
    id: lastId,
    expiryState: 0,
    deliveredState: 0,
    undeliveredState: 0,
    modalState: "",
    modalEdit: "",
    modalID: "",
    carInfo: [],
    expiryList: [],
    deliveredList: [],
    undeliveredList: [],
  },
  reducers: {
    getDashResult: (state, action) => {
      state.id += 1;
      state.expiryState = action.payload.expiryState;
      state.deliveredState = action.payload.deliveredState;
      state.undeliveredState = action.payload.undeliveredState;
    },
    getModal: (state, action) => {
      state.id += 1;
      state.modalState = action.payload.modalState;
      state.modalEdit = action.payload.modalEdit;
      state.modalID = action.payload.modalID;
    },
    getCar: (state, action) => {
      state.id += 1;
      state.carInfo = action.payload.carInfo;
    },

    // getDate: (state, action) => {
    //   state.id += 1;
    //   state.edithDate = action.payload.edithDate;
    //   // state.organization = action.payload.organization;
    // },
    // getPayList: (state, action) => {
    //   state.id += 1;
    //   state.payList = action.payload.payList;
    //   // state.organization = action.payload.organization;
    // },
  },
});

export const {
  getDashResult,
  getModal,
  getCar,
  // getEdith,
  // getDate,
  // getList,
  // getPayList,
  // getMyStae,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
