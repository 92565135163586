import React, { useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { Toast } from "bootstrap";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

function ChangeExpDate() {
  const dispatch = useDispatch();
  const modalEdit = useSelector((state) => state.dashboard.modalEdit);
  const modalID = useSelector((state) => state.dashboard.modalID);

  const [dashData, setDashData] = useState({
    disabled: false,
    status: false,
    ExDate: modalEdit,
    errExDate: "",
    toastMSG: "",
    statusMSG: "",
  });
  function ModalClose(event) {
    dispatch(
      reducer.getModal({
        modalState: "",
      })
    );

    setDashData((prevDashData) => ({
      ...prevDashData,
      disabled: false,
      status: false,
      ExDate: "",
      errExDate: "",
      toastMSG: "",
      statusMSG: "",
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    // const x = new Date('2013-05-23');
    if (name === "ExDate") {
      let errMsg = dashData.errExDate;
      const orgInputValue = event.target.value.trim();
      if (new Date(orgInputValue) > new Date(modalEdit)) {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errExDate: "",
          [name]: value,
        }));
      } else {
        errMsg = "Date can not be less than " + modalEdit;
        setDashData((prevDashData) => ({
          ...prevDashData,
          errExDate: errMsg,
        }));
      }
    }
  }
  function ModalUpdate(params) {
    let err = 0;
    let errExDate = dashData.errExDate;
    let ExDate = dashData.ExDate;
    if (errExDate) {
      err += 1;
    } else if (ExDate === modalEdit) {
      err += 1;
      setDashData((prevDashData) => ({
        ...prevDashData,
        errExDate: "Date is the same",
      }));
    } else {
      err += 0;
    }
    if (err > 0) {
      let toastMSG = "Fix warning & Reupdate";
      let statusMSG = "Update";
      let status = true;
      let disabled = false;
      setDashData((prevDashData) => ({
        ...prevDashData,
        toastMSG: toastMSG,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));

      const toastLiveExample = document.getElementById("Toastmodal");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();
    } else {
      let toastMSG = "Wait it's Updating ...";
      let statusMSG = " Updating ...";
      let status = true;
      let disabled = true;
      setDashData((prevDashData) => ({
        ...prevDashData,
        statusMSG: statusMSG,
        toastMSG: toastMSG,
        status: status,
        disabled: disabled,
      }));
      const toastLiveExample = document.getElementById("Toastmodal");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();

      async function main() {
        const endpoint = env.SERVER_URL + "api/sms";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          Date_Expire: dashData.ExDate,
          AUTOID: modalID,
        };
        const query = gql`
          mutation ($Date_Expire: String!, $AUTOID: String!) {
            Update_ExpireDate(Date_Expire: $Date_Expire, AUTOID: $AUTOID) {
              AUTOID
              PLATE_ID
              NUMBER
              ONAME
              BRAND
              ExpireDay
              ExpireMonth
              ExpireYear
              C_P
              sms
              smscount
              Date_Sent
              Date_Expire
              CHASSIS_NO
              ENGINE_ID
            }
          }
        `;
        const data = await graphQLClient.request(query, variables);

        if (data["Update_ExpireDate"].length > 0) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            ExDate: data["Update_ExpireDate"][0]["Date_Expire"],
            errExDate: "",
            status: false,
            toastMSG: "Successfully",
            statusMSG: "Update",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
          dispatch(
            reducer.getCar({
              carInfo: data["Update_ExpireDate"][0],
            })
          );
        } else {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errExDate: "",
            status: false,
            toastMSG: "Error",
            statusMSG: "Update",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      }
      main().catch((error) => {
        // console.error(error);
        let message = error.message.substring(0, 25);
        // let message2 = error.message.split('" ')[0];
        // console.log(message);
        if ("Network request failed" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errExDate: "",
            status: false,
            toastMSG: "NO INTERNET",
            disabled: false,
          }));
          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }

        if ("GraphQL Error (Code: 409)" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            status: false,
            toastMSG: "Error",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      });
    }
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Update Expiration Date</h4>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={ModalClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-sm-12">
            <label>Expiration Date</label>
            <input
              className="form-control form-control-border border-width-2"
              type="date"
              id="ExDate"
              name="ExDate"
              onChange={handleChange}
              value={dashData.ExDate}
              // value={modalEdit}
              autoComplete="ExDate"
              disabled={dashData.disabled}
              style={{
                borderBottom: dashData.errExDate && "3px solid red",
              }}
            />
            <span
              style={{
                color: "rgb(243, 182, 51)",
                margin: "0px 0px 30px 30px",
              }}
            >
              {dashData.errExDate}
            </span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger "
          data-bs-dismiss="modal"
          onClick={ModalClose}
        >
          Close
        </button>
        <button
          type="button"
          disabled={dashData.disabled}
          className="btn btn-primary"
          onClick={ModalUpdate}
        >
          {dashData.disabled && (
            <span
              className="spinner-border text-light spinner-border-sm "
              role="status"
              aria-hidden="true"
            >
              {/* <ImSpinner9 className="sr-only" /> */}
            </span>
          )}
          <span>{dashData.status ? dashData.statusMSG : " Update"}</span>
        </button>
      </div>
      {/* toast-container */}
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div
          id="Toastmodal"
          className="toast align-items-center cs_toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">
              <h5>{dashData.toastMSG}</h5>
            </div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeExpDate;
