import React, { useEffect, useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";
import { Toast, Modal } from "bootstrap";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

// import { Link } from "react-router-dom";

import { FaCarSide } from "react-icons/fa";
import { BiMailSend, BiEdit, BiRefresh } from "react-icons/bi";
import { FaSms } from "react-icons/fa";

import ChangePhoneNo from "../Modals/ChangePhoneNo";
import ChangeExpDate from "../Modals/ChangeExpDate";
import ChangeSinglesms from "../Modals/ChangeSinglesms";
import ChangeBulksms from "../Modals/ChangeBulksms";

function Dashboard() {
  const dispatch = useDispatch();
  const [dashData, setDashData] = useState({
    // Get_expiry: 0,
    // Get_Delivered: 0,
    // Get_Undelivered: 0,
    disabled: false,
    status: false,
    PlateNo: "",
    errPlateNo: "",
    toastMSG: "",
    SMSState: "",
  });

  const expiryState = useSelector((state) => state.dashboard.expiryState);
  const deliveredState = useSelector((state) => state.dashboard.deliveredState);
  const undeliveredState = useSelector(
    (state) => state.dashboard.undeliveredState
  );
  const modalState = useSelector((state) => state.dashboard.modalState);
  const carInfo = useSelector((state) => state.dashboard.carInfo);

  useEffect(() => {
    async function main() {
      const endpoint = env.SERVER_URL + "api/sms";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });

      const Get_Send_Bulk = gql`
        mutation {
          Send_Bulk
        }
      `;

      if (dashData.SMSState === "ALL SMS SENT") {
        const AllSMS = await graphQLClient.request(Get_Send_Bulk);
        console.log(AllSMS);
        setDashData((prevDashData) => ({
          ...prevDashData,
          toastMSG: "ALL SMS SENT",
          SMSState: "ALL SMS SENT",
        }));

        if (AllSMS) {
          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      } else {
        setDashData((prevDashData) => ({
          ...prevDashData,
          toastMSG: "ALL SMS SENT",
          SMSState: "ALL SMS SENT",
        }));
      }

      const Get_Expiry = gql`
        {
          Get_Expiry
        }
      `;
      const Get_Delivered = gql`
        {
          Get_Delivered
        }
      `;
      const Get_Undelivered = gql`
        {
          Get_Undelivered
        }
      `;
      const AllOrg = await graphQLClient.request(Get_Expiry);
      const AllINV = await graphQLClient.request(Get_Delivered);
      const AllUN = await graphQLClient.request(Get_Undelivered);

      let Count_Expiry = AllOrg["Get_Expiry"];
      let Count_Delivered = AllINV["Get_Delivered"];
      let Count_Undelivered = AllUN["Get_Undelivered"];
      dispatch(
        reducer.getDashResult({
          expiryState: Count_Expiry,
          deliveredState: Count_Delivered,
          undeliveredState: Count_Undelivered,
        })
      );
    }
    main().catch((error) => {
      console.error(error);
    });
  }, [dashData.SMSState, dispatch]);

  // const d = new Date().toISOString().slice(0, 10);
  // var dxc = new Date();
  // dxc.setDate(dxc.getDate() + Number(3));
  // var dx = dxc.toISOString().slice(0, 10);
  // const G_yr = dx.slice(0, 4);
  // const G_mth = dx.slice(5, 7);
  // const G_day = dx.slice(8, 10);

  // console.log(G_yr, G_mth, G_day, d);
  function handleChange(event) {
    const { name, value } = event.target;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*<>])/;

    if (name === "PlateNo") {
      let errMsg = dashData.errPlateNo;
      const orgInputValue = event.target.value.trim();
      const minLengthRegExp = /.{5,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "Plate Number is empty";
      } else if (orgLength > 15) {
        errMsg = "maximum 15 characters";
      } else if (specialCharorg) {
        errMsg = "Special Characters is not allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 5 characters";
      } else {
        errMsg = "";
      }
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPlateNo: errMsg,
      }));
    }

    setDashData((prevDashData) => ({
      ...prevDashData,
      [name]: value.toUpperCase(),
    }));
  }

  function handleClear(params) {
    const { name } = params.target;

    setDashData((prevDashData) => ({
      ...prevDashData,
      [name]: "",
      disabled: false,
      status: false,
    }));
    dispatch(
      reducer.getCar({
        carInfo: [],
      })
    );
  }
  function ModalExp(event) {
    if (carInfo.Date_Expire === "0000-00-00") {
      const Date_Expire =
        carInfo.ExpireYear +
        "-" +
        carInfo.ExpireMonth +
        "-" +
        carInfo.ExpireDay;
      // console.log(Date_Expire);
      async function main() {
        const endpoint = env.SERVER_URL + "api/sms";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          Date_Expire: Date_Expire,
          AUTOID: carInfo.AUTOID,
        };
        const query = gql`
          mutation ($Date_Expire: String!, $AUTOID: String!) {
            Update_ExpireDate(Date_Expire: $Date_Expire, AUTOID: $AUTOID) {
              AUTOID
              PLATE_ID
              NUMBER
              ONAME
              BRAND
              ExpireDay
              ExpireMonth
              ExpireYear
              C_P
              sms
              smscount
              Date_Sent
              Date_Expire
              CHASSIS_NO
              ENGINE_ID
            }
          }
        `;
        const data = await graphQLClient.request(query, variables);

        if (data["Update_ExpireDate"].length > 0) {
          dispatch(
            reducer.getCar({
              carInfo: data["Update_ExpireDate"][0],
            })
          );
          dispatch(
            reducer.getModal({
              modalState: "ModalExp",
              modalEdit: data["Update_ExpireDate"][0]["Date_Expire"],
              modalID: data["Update_ExpireDate"][0]["AUTOID"],
            })
          );

          const modalChange = document.getElementById("modalChange");
          const modalShow = new Modal(modalChange);
          modalShow.show();
        }
      }
      main().catch((error) => {
        // console.error(error);
        let message = error.message.substring(0, 25);
        // let message2 = error.message.split('" ')[0];
        // console.log(message);
        if ("Network request failed" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errpocode: "",
            status: false,
            toastMSG: "NO INTERNET",
            disabled: false,
          }));
          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }

        if ("GraphQL Error (Code: 409)" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            status: false,
            toastMSG: "Error",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      });
    } else {
      dispatch(
        reducer.getModal({
          modalState: "ModalExp",
          modalEdit: carInfo.Date_Expire,
          modalID: carInfo.AUTOID,
        })
      );

      const modalChange = document.getElementById("modalChange");
      const modalShow = new Modal(modalChange);
      modalShow.show();
    }
  }
  function ModalPhone(event) {
    dispatch(
      reducer.getModal({
        modalState: "ModalPhone",
        modalEdit: carInfo.NUMBER,
        modalID: carInfo.AUTOID,
      })
    );

    const modalChange = document.getElementById("modalChange");
    const modalShow = new Modal(modalChange);
    modalShow.show();
  }
  function ModalSinglesms(event) {
    dispatch(
      reducer.getModal({
        modalState: "ModalSinglesms",
        modalEdit: carInfo.NUMBER,
        modalID: carInfo.AUTOID,
        toastMSG: "",
      })
    );

    const modalChange = document.getElementById("modalChange");
    const modalShow = new Modal(modalChange);
    modalShow.show();
  }
  function ModalBulksms(event) {
    dispatch(
      reducer.getModal({
        modalState: "ModalBulksms",
        modalEdit: carInfo.NUMBER,
        modalID: carInfo.AUTOID,
        toastMSG: "",
      })
    );

    const modalChange = document.getElementById("modalChange");
    const modalShow = new Modal(modalChange);
    modalShow.show();
  }

  function InfoRefresh(params) {
    let toastMSG = "Wait it's Updating ...";
    let statusMSG = "Wait it's Updating ...";
    let status = true;
    let disabled = true;
    setDashData((prevDashData) => ({
      ...prevDashData,
      statusMSG: statusMSG,
      toastMSG: toastMSG,
      status: status,
      disabled: disabled,
    }));
    const toastLiveExample = document.getElementById("liveToast");
    const toastShow = new Toast(toastLiveExample);
    toastShow.show();
    async function main() {
      const endpoint = env.SERVER_URL + "api/sms";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });
      const variables = {
        PLATE_ID: dashData.PlateNo,
      };
      const query = gql`
        mutation ($PLATE_ID: String!) {
          Refresh_Car(PLATE_ID: $PLATE_ID) {
            AUTOID
            PLATE_ID
            NUMBER
            ONAME
            BRAND
            ExpireDay
            ExpireMonth
            ExpireYear
            C_P
            sms
            smscount
            Date_Sent
            Date_Expire
            CHASSIS_NO
            ENGINE_ID
          }
        }
      `;
      const data = await graphQLClient.request(query, variables);

      if (data["Refresh_Car"].length > 0) {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errpocode: "",
          status: false,
          toastMSG: "Successfully",
          disabled: false,
        }));
        dispatch(
          reducer.getCar({
            carInfo: data["Refresh_Car"][0],
          })
        );
        const toastLiveExample = document.getElementById("liveToast");
        const toastShow = new Toast(toastLiveExample);
        toastShow.show();
      } else {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errpocode: "",
          status: false,
          toastMSG: "No Records",
          disabled: false,
        }));
        dispatch(
          reducer.getCar({
            carInfo: [],
          })
        );
        const toastLiveExample = document.getElementById("liveToast");
        const toastShow = new Toast(toastLiveExample);
        toastShow.show();
      }
    }
    main().catch((error) => {
      // console.error(error);
      let message = error.message.substring(0, 25);
      // let message2 = error.message.split('" ')[0];
      console.log(message);
      if ("Network request failed" === message) {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errpocode: "",
          status: false,
          toastMSG: "NO INTERNET",
          disabled: false,
        }));
        const toastLiveExample = document.getElementById("liveToast");
        const toastShow = new Toast(toastLiveExample);
        toastShow.show();
      }
      if ("GraphQL Error (Code: 409)" === message) {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errpocode: "NO RECORDS",
          status: false,
          toastMSG: "NO RECORDS",
          disabled: false,
        }));

        const toastLiveExample = document.getElementById("liveToast");
        const toastShow = new Toast(toastLiveExample);
        toastShow.show();
      }
      if ("Cannot read properties of" === message) {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errpocode: "NO RECORDS",
          status: false,
          toastMSG: "NO RECORDS",
          disabled: false,
        }));

        const toastLiveExample = document.getElementById("liveToast");
        const toastShow = new Toast(toastLiveExample);
        toastShow.show();
      }
    });
  }
  function handleSubmit(event) {
    event.preventDefault();
    // setResultData((prevResultData) => ({
    //   ...prevResultData,
    //   Get_AUTOID: "",
    // }));
    let err = 0;

    let errPlateNo = dashData.errPlateNo;

    if (errPlateNo) {
      err += 1;
    } else {
      err += 0;
    }

    if (err > 0) {
      // toast.error("Fix warning & Resubmit");
      const toastLiveExample = document.getElementById("liveToast");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();

      let statusMSG = "Fix warning & Research";
      let status = true;
      let disabled = false;
      setDashData((prevDashData) => ({
        ...prevDashData,
        toastMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      let statusMSG = " Searching ---";
      let status = true;
      let disabled = true;
      setDashData((prevDashData) => ({
        ...prevDashData,
        toastMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
      async function main() {
        const endpoint = env.SERVER_URL + "api/sms";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          PLATE_ID: dashData.PlateNo,
        };
        const query = gql`
          query ($PLATE_ID: String!) {
            List_Sms(PLATE_ID: $PLATE_ID) {
              AUTOID
              PLATE_ID
              NUMBER
              ONAME
              BRAND
              ExpireDay
              ExpireMonth
              ExpireYear
              C_P
              sms
              smscount
              Date_Sent
              Date_Expire
              CHASSIS_NO
              ENGINE_ID
            }
          }
        `;
        const data = await graphQLClient.request(query, variables);

        if (data["List_Sms"].length > 0) {
          if (data["List_Sms"][0]["ONAME"] === null) {
            const queryReupdate_info = gql`
              mutation ($PLATE_ID: String!) {
                ReUpdate_info(PLATE_ID: $PLATE_ID) {
                  AUTOID
                  PLATE_ID
                  NUMBER
                  ONAME
                  BRAND
                  ExpireDay
                  ExpireMonth
                  ExpireYear
                  C_P
                  sms
                  smscount
                  Date_Sent
                  Date_Expire
                  CHASSIS_NO
                  ENGINE_ID
                }
              }
            `;
            const dataReupdate_info = await graphQLClient.request(
              queryReupdate_info,
              variables
            );
            if (dataReupdate_info["ReUpdate_info"].length > 0) {
              setDashData((prevDashData) => ({
                ...prevDashData,
                errpocode: "",
                status: false,
                toastMSG: "Successfully",
                disabled: false,
              }));
              dispatch(
                reducer.getCar({
                  carInfo: dataReupdate_info["ReUpdate_info"][0],
                })
              );
            }
          } else {
            setDashData((prevDashData) => ({
              ...prevDashData,
              errpocode: "",
              status: false,
              toastMSG: "Successfully",
              disabled: false,
            }));
            dispatch(
              reducer.getCar({
                carInfo: data["List_Sms"][0],
              })
            );
          }

          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        } else {
          const queryReupdate = gql`
            mutation ($PLATE_ID: String!) {
              ReUpdate_Car(PLATE_ID: $PLATE_ID) {
                AUTOID
                PLATE_ID
                NUMBER
                ONAME
                BRAND
                ExpireDay
                ExpireMonth
                ExpireYear
                C_P
                sms
                smscount
                Date_Sent
                Date_Expire
                CHASSIS_NO
                ENGINE_ID
              }
            }
          `;
          const dataReupdate = await graphQLClient.request(
            queryReupdate,
            variables
          );
          if (dataReupdate["ReUpdate_Car"].length > 0) {
            setDashData((prevDashData) => ({
              ...prevDashData,
              errpocode: "",
              status: false,
              toastMSG: "Successfully",
              disabled: false,
            }));
            dispatch(
              reducer.getCar({
                carInfo: dataReupdate["ReUpdate_Car"][0],
              })
            );

            const toastLiveExample = document.getElementById("liveToast");
            const toastShow = new Toast(toastLiveExample);
            toastShow.show();
          } else {
            setDashData((prevDashData) => ({
              ...prevDashData,
              errpocode: "",
              status: false,
              toastMSG: "No Records",
              disabled: false,
            }));
            dispatch(
              reducer.getCar({
                carInfo: [],
              })
            );
            const toastLiveExample = document.getElementById("liveToast");
            const toastShow = new Toast(toastLiveExample);
            toastShow.show();
          }
        }
      }
      main().catch((error) => {
        // console.error(error);
        let message = error.message.substring(0, 25);
        // let message2 = error.message.split('" ')[0];
        console.log(message);
        if ("Network request failed" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errpocode: "",
            status: false,
            toastMSG: "NO INTERNET",
            disabled: false,
          }));
          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
        if ("GraphQL Error (Code: 409)" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errpocode: "NO RECORDS",
            status: false,
            toastMSG: "NO RECORDS",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
        if ("Cannot read properties of" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errpocode: "NO RECORDS",
            status: false,
            toastMSG: "NO RECORDS",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      });
    }
  }

  return (
    <div className="container">
      <section className="row pt-5">
        <div className="col-sm-4 p-3">
          <div className="shadow cs_l_d_blue rounded-3">
            <div className=" row ">
              <div className="col-sm-1">
                <FaCarSide className="cs_d_icon" />
              </div>

              <div className="col-sm-10 text-center">
                <span className="cs_d_txt">
                  {expiryState === 0 ? (
                    <span
                      className="spinner-grow text-light "
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    expiryState
                  )}
                </span>
              </div>
            </div>
            <div className="p-3 text-white">
              <b>3 days to expiry</b>
            </div>
          </div>
        </div>
        <div className="col-sm-4 p-3">
          <div className="shadow cs_l_d_green rounded-3">
            <div className=" row ">
              <div className="col-sm-1">
                <BiMailSend className="cs_d_icon" />
              </div>

              <div className="col-sm-10 text-center">
                <span className="cs_d_txt">
                  {deliveredState === 0 ? (
                    <span
                      className="spinner-grow text-light "
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    deliveredState
                  )}
                </span>
              </div>
            </div>
            <div className="p-3 text-white">
              <b>Delivered SMS Today</b>
            </div>
          </div>
        </div>
        <div className="col-sm-4 p-3">
          <div className="shadow cs_l_d_red rounded-3">
            <div className=" row ">
              <div className="col-sm-1">
                <BiMailSend className="cs_d_icon" />
              </div>

              <div className="col-sm-10 text-center">
                <span className="cs_d_txt">
                  {undeliveredState === 0 ? (
                    <span
                      className="spinner-grow text-light "
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    undeliveredState
                  )}
                </span>
              </div>
            </div>
            <div className="p-3 text-white">
              <b>Undelivered SMS Today</b>
            </div>
          </div>
        </div>
      </section>
      <section className="row pt-3 ">
        <div className="p-3">
          <div className="shadow border rounded-3 p-4 ">
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-12">
                <label className="form-label">
                  <h4>Search by No. Plate</h4>
                </label>

                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="PlateNo"
                    name="PlateNo"
                    onChange={handleChange}
                    value={dashData.PlateNo}
                    autoComplete="PlateNo"
                    minLength="5"
                    maxLength="15"
                    disabled={dashData.disabled}
                    required={{
                      borderBottom: dashData.errPlateNo ? true : false,
                    }}
                    style={{
                      borderBottom: dashData.errPlateNo && "3px solid red",
                    }}
                    placeholder="Search by No. Plate"
                  />
                  <button
                    type="button"
                    id="PlateNo"
                    name="PlateNo"
                    className="btn btn-outline-secondary"
                    onClick={handleClear}
                  >
                    X
                  </button>
                </div>
                <span
                  style={{
                    color: "rgb(243, 182, 51)",
                    margin: "0px 0px 0px 20px",
                  }}
                >
                  {dashData.errPlateNo}
                </span>
              </div>
              <div className="col-12">
                <button
                  disabled={dashData.disabled}
                  type="submit"
                  className="btn btn-primary"
                >
                  {dashData.disabled && (
                    <span
                      className="spinner-border text-light spinner-border-sm "
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span>
                    {dashData.status ? dashData.toastMSG : " --- Search ---"}
                  </span>
                </button>
              </div>
            </form>
            <div className="row pt-3 overflow-auto">
              {carInfo.AUTOID && (
                <span>
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan="2">
                          Vehicle Records For ( {carInfo.PLATE_ID} )
                          <BiRefresh
                            onClick={InfoRefresh}
                            fontSize={25}
                            color={"green"}
                            cursor={"pointer"}
                          />
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          <b>{carInfo.ONAME}</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Phone No.</td>
                        <td>
                          <b>{carInfo.NUMBER}</b>
                        </td>
                        <td>
                          <BiEdit
                            onClick={ModalPhone}
                            fontSize={25}
                            color={"green"}
                            cursor={"pointer"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Vehicle</td>
                        <td>
                          <b>{carInfo.BRAND}</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Chassis </td>
                        <td>
                          <b>{carInfo.CHASSIS_NO}</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Amount</td>
                        <td>
                          <b>{carInfo.ENGINE_ID}</b>
                        </td>
                        <td>
                          {carInfo.ENGINE_ID === "CMV UP TO 2000 CC" && (
                            <span>
                              <b>83</b>
                            </span>
                          )}
                          {carInfo.ENGINE_ID === "PMV UP TO 2000 CC" && (
                            <span>
                              <b>140</b>
                            </span>
                          )}
                          {carInfo.ENGINE_ID === "PMV ABOVE 2000 CC" && (
                            <span>
                              <b>160</b>
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Expiration Date</td>
                        <td>
                          <b>
                            {carInfo.ExpireDay +
                              "/" +
                              carInfo.ExpireMonth +
                              "/" +
                              carInfo.ExpireYear}
                          </b>
                        </td>
                        <td>
                          <BiEdit
                            onClick={ModalExp}
                            fontSize={25}
                            color={"green"}
                            cursor={"pointer"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Last SMS Date</td>
                        <td>
                          <b>{carInfo.Date_Sent}</b>
                        </td>
                        <td>
                          <b>{carInfo.C_P + "/" + carInfo.smscount}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className=" row ">
                    <div
                      onClick={ModalSinglesms}
                      className="col-sm-2 p-2 m-2 cs_sm_icon rounded-3"
                    >
                      Send sms <FaSms fontSize={25} color={"blue"} />
                    </div>
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="row pt-3 ">
        <div className="p-3">
          <div
            onClick={ModalBulksms}
            className="col-sm-2 p-2 m-2 cs_sm_icon rounded-3"
          >
            Send bulk sms <FaSms fontSize={25} color={"blue"} />
          </div>
        </div>
      </section>

      {/* toast-container */}
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div
          id="liveToast"
          className="toast align-items-center cs_toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">
              <h5>{dashData.toastMSG}</h5>
            </div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            />
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className="modal fade"
        id="modalChange"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        {/* <div className="modal fade" id="modalChange"> */}
        <div className="modal-dialog">
          {modalState === "ModalPhone" && <ChangePhoneNo />}
          {modalState === "ModalExp" && <ChangeExpDate />}
          {modalState === "ModalSinglesms" && <ChangeSinglesms />}
          {modalState === "ModalBulksms" && <ChangeBulksms />}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
