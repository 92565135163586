import React, { useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { Toast } from "bootstrap";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

function ChangePhoneNo() {
  const dispatch = useDispatch();

  const modalEdit = useSelector((state) => state.dashboard.modalEdit);
  const modalID = useSelector((state) => state.dashboard.modalID);

  const [dashData, setDashData] = useState({
    disabled: false,
    status: false,
    PhoneNo: "",
    errPhoneNo: "",
    PhoneNo2: "",
    errPhoneNo2: "",

    toastMSGsms: "",
    statusMSG: "",
    Feedbackinfo: "",
  });

  function ModalClose(event) {
    dispatch(
      reducer.getModal({
        modalState: "",
      })
    );

    setDashData((prevDashData) => ({
      ...prevDashData,
      disabled: false,
      status: false,
      PhoneNo: "",
      errPhoneNo: "",
      toastMSGsms: "",
      statusMSG: "",
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const specialCharRegExp = /(?=.*?[#?!@$%^*<>])/;

    if (name === "PhoneNo") {
      let errMsg = dashData.errPlateNo;
      const orgInputValue = event.target.value.trim();
      const minLengthRegExp = /.{10,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "Message is empty";
      } else if (orgLength > 100) {
        errMsg = "maximum 100 characters";
      } else if (specialCharorg) {
        errMsg = "No special characters or emoji.";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 10 characters";
      } else {
        var FeedbackNo = 100 - Number(orgLength);
        var Feedbackinfo = FeedbackNo + " out of 100 characters";

        errMsg = "";
      }
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPhoneNo: errMsg,
        Feedbackinfo: Feedbackinfo,
      }));
    }

    if (name === "PhoneNo2") {
      let errMsg = dashData.errPlateNo2;
      const orgInputValue = event.target.value.trim();
      const minLengthRegExp = /.{4,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "OTP Code is empty";
      } else if (orgLength > 4) {
        errMsg = "maximum 4 characters";
      } else if (specialCharorg) {
        errMsg = "Only numbers are allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 4 characters";
      } else if (orgInputValue !== "8426") {
        errMsg = "Invalid OTP Code";
      } else {
        errMsg = "";
      }
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPhoneNo2: errMsg,
      }));
    }

    setDashData((prevDashData) => ({
      ...prevDashData,
      [name]: value.toUpperCase(),
    }));
  }
  function ModalUpdate(params) {
    let err = 0;
    if (dashData.errPhoneNo) {
      err += 1;
    } else if (dashData.errPhoneNo2) {
      err += 1;
    } else if (!dashData.PhoneNo) {
      err += 1;
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPhoneNo: "Message is empty",
      }));
    } else if (!dashData.PhoneNo2) {
      err += 1;
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPhoneNo2: "OTP Code is empty",
      }));
    } else {
      err += 0;
    }
    if (err > 0) {
      let toastMSGsms = "Fix warning & Resend";
      let statusMSG = "Send SMS";
      let status = true;
      let disabled = false;
      setDashData((prevDashData) => ({
        ...prevDashData,
        toastMSGsms: toastMSGsms,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));

      const toastLiveExample = document.getElementById("Toastmodal");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();
    } else {
      let toastMSGsms = "Wait it's Sending ...";
      let statusMSG = " Sending ...";
      let status = true;
      let disabled = true;
      setDashData((prevDashData) => ({
        ...prevDashData,
        statusMSG: statusMSG,
        toastMSGsms: toastMSGsms,
        status: status,
        disabled: disabled,
      }));
      const toastLiveExample = document.getElementById("Toastmodal");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();

      async function main() {
        const endpoint = env.SERVER_URL + "api/sms";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          NUMBER: modalEdit,
          MSG: dashData.PhoneNo,
          AUTOID: modalID,
        };
        const query = gql`
          mutation ($NUMBER: String!, $AUTOID: String!, $MSG: String!) {
            Send_Singlesms(NUMBER: $NUMBER, AUTOID: $AUTOID, MSG: $MSG) {
              AUTOID
              PLATE_ID
              NUMBER
              ONAME
              BRAND
              ExpireDay
              ExpireMonth
              ExpireYear
              C_P
              sms
              smscount
              Date_Sent
              Date_Expire
              CHASSIS_NO
              ENGINE_ID
            }
          }
        `;
        const data = await graphQLClient.request(query, variables);

        if (data["Send_Singlesms"][0]["NUMBER"].length > 0) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            // PhoneNo: data["Send_Singlesms"][0]["NUMBER"],
            errPhoneNo: "",
            Feedbackinfo: "",
            status: false,
            toastMSGsms: "Successfully",
            statusMSG: "Sent",
            disabled: false,
            PhoneNo: "",
            PhoneNo2: "",
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
          dispatch(
            reducer.getCar({
              carInfo: data["Send_Singlesms"][0],
            })
          );
        } else {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errPhoneNo: "",
            status: false,
            toastMSGsms: "ERROR",
            statusMSG: "ERROR",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      }
      main().catch((error) => {
        // console.error(error);
        let message = error.message.substring(0, 25);
        // let message2 = error.message.split('" ')[0];
        // console.log(message);
        if ("Network request failed" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errPhoneNo: "",
            status: false,
            toastMSGsms: "NO INTERNET",
            statusMSG: "ERROR",
            disabled: false,
          }));
          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        } else if ("GraphQL Error (Code: 409)" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            status: false,
            toastMSGsms: "Error",
            statusMSG: "ERROR",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        } else {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errPhoneNo: "",
            status: false,
            toastMSGsms: "ERROR",
            disabled: false,
          }));
          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      });
    }
  }
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Send SMS to {modalEdit}</h4>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={ModalClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="">
          <div className="row">
            <div className="col-sm-12">
              <label>Message * (100 characters)</label>
              <textarea
                className="form-control form-control-border border-width-2"
                type="textarea"
                rows="3"
                id="PhoneNo"
                name="PhoneNo"
                onChange={handleChange}
                value={dashData.PhoneNo}
                autoComplete="PhoneNo"
                minLength="10"
                maxLength="100"
                // placeholder={modalEdit}
                disabled={dashData.disabled}
                required
                style={{
                  borderBottom: dashData.errPhoneNo && "3px solid red",
                }}
              ></textarea>
              <span
                style={{
                  color: "rgb(243, 182, 51)",
                  margin: "0px 0px 30px 30px",
                }}
              >
                {dashData.errPhoneNo}
              </span>
              <span>{dashData.Feedbackinfo}</span>
            </div>
            <div className="col-sm-12">
              <label>OTP Code</label>
              <input
                className="form-control form-control-border border-width-2"
                type="text"
                id="PhoneNo2"
                name="PhoneNo2"
                onChange={handleChange}
                value={dashData.PhoneNo2}
                autoComplete="PhoneNo2"
                minLength="4"
                maxLength="4"
                // placeholder={modalEdit}
                required
                disabled={dashData.disabled}
                style={{
                  borderBottom: dashData.errPhoneNo2 && "3px solid red",
                }}
              />
              <span
                style={{
                  color: "rgb(243, 182, 51)",
                  margin: "0px 0px 30px 30px",
                }}
              >
                {dashData.errPhoneNo2}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger "
          data-bs-dismiss="modal"
          onClick={ModalClose}
        >
          Close
        </button>
        <button
          type="button"
          disabled={dashData.disabled}
          className="btn btn-primary"
          onClick={ModalUpdate}
        >
          {dashData.disabled && (
            <span
              className="spinner-border text-light spinner-border-sm "
              role="status"
              aria-hidden="true"
            >
              {/* <ImSpinner9 className="sr-only" /> */}
            </span>
          )}
          <span>{dashData.status ? dashData.statusMSG : " Send SMS"}</span>
        </button>
      </div>
      {/* toast-container */}
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div
          id="Toastmodal"
          className="toast align-items-center cs_toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">
              <h5>{dashData.toastMSGsms}</h5>
            </div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePhoneNo;
