import React, { useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

function Sync() {
  const [dashData, setDashData] = useState({
    // Get_expiry: 0,
    // Get_Delivered: 0,
    // Get_Undelivered: 0,
    disabled: false,
    status: false,
    syncDate: "",
    errsyncDate: "",
    toastMSG: "",
    SMSState: "",
    syncInfo: "",
  });
  function handleChange(params) {
    const { name, value } = params.target;
    // const x = new Date('2013-05-23');
    if (name === "syncDate") {
      let errMsg = dashData.errExDate;
      const orgInputValue = params.target.value.trim();
      if (new Date(orgInputValue) < new Date()) {
        setDashData((prevDashData) => ({
          ...prevDashData,
          errsyncDate: "",
          [name]: value,
        }));
      } else {
        errMsg =
          "Date can not be more than " +
          new Date().toISOString().slice(0, 10).replace(/t/gi, " ");
        setDashData((prevDashData) => ({
          ...prevDashData,
          errsyncDate: errMsg,
        }));
      }
    }
  }
  function handleSubmit(params) {
    params.preventDefault();

    let err = 0;
    if (dashData.errsyncDate) {
      err += 1;
    }
    if (!dashData.syncDate) {
      err += 1;
    }
    if (err > 0) {
      let toastMSG = "Fix warning & Reupdate";
      let statusMSG = "Synchronize ";
      let status = true;
      let disabled = false;
      setDashData((prevDashData) => ({
        ...prevDashData,
        toastMSG: toastMSG,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      //   console.log(dashData.syncDate);
      let toastMSG = " Wait it's synchronizing ...";
      let statusMSG = " Synchronizing ...";
      let status = true;
      let disabled = true;
      setDashData((prevDashData) => ({
        ...prevDashData,
        statusMSG: statusMSG,
        toastMSG: toastMSG,
        status: status,
        disabled: disabled,
      }));
      async function main() {
        const endpoint = env.SERVER_URL + "api/sms";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          syncDate: dashData.syncDate,
        };
        const query = gql`
          mutation ($syncDate: String!) {
            Sync_Data(syncDate: $syncDate)
          }
        `;
        const data = await graphQLClient.request(query, variables);
        if (data["Sync_Data"] === "Successfully") {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errsyncDate: "",
            status: false,
            toastMSG: "Successfully",
            statusMSG: "Synchronize",
            disabled: false,
            syncInfo: data["Sync_Data"],
          }));
        } else {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errsyncDate: "",
            status: false,
            toastMSG: "Error",
            statusMSG: "Synchronize",
            disabled: false,
          }));
        }
      }
      main().catch((error) => {
        // console.error(error);
        let message = error.message.substring(0, 25);
        // let message2 = error.message.split('" ')[0];
        // console.log(message);
        if ("Network request failed" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errsyncDate: "",
            status: false,
            toastMSG: "NO INTERNET",
            disabled: false,
          }));
        }

        if ("ER_ACCESS_DENIED_ERROR: A" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            status: false,
            toastMSG: "Error",
            disabled: false,
            errsyncDate: "No Access to the database",
            syncDate: "",
          }));
        }
        if ("GraphQL Error (Code: 409)" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            status: false,
            toastMSG: "Error",
            disabled: false,
          }));
        }
      });
    }
  }
  return (
    <div className="container">
      <section className="row pt-5 pb-5">
        <div className="shadow border rounded-3 p-4">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-12">
              <label className="form-label">
                <h4>synchronize by Date</h4>
              </label>

              <div className="input-group">
                <input
                  type="date"
                  className="form-control"
                  id="syncDate"
                  name="syncDate"
                  onChange={handleChange}
                  value={dashData.syncDate}
                  disabled={dashData.disabled}
                  required={{
                    borderBottom: dashData.errsyncDate ? true : false,
                  }}
                  style={{
                    borderBottom: dashData.errsyncDate && "3px solid red",
                  }}
                />
                <button
                  disabled={dashData.disabled}
                  type="submit"
                  className="btn btn-primary"
                >
                  {dashData.disabled && (
                    <span
                      className="spinner-border text-light spinner-border-sm "
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span>
                    {dashData.status
                      ? dashData.toastMSG
                      : " --- Synchronize ---"}
                  </span>
                </button>
              </div>
              <span
                style={{
                  color: "rgb(243, 182, 51)",
                  margin: "0px 0px 0px 20px",
                }}
              >
                {dashData.errsyncDate}
              </span>
            </div>
          </form>
          <div className="row pt-3 overflow-auto">
            {dashData.syncInfo && (
              <span>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Synchronize Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{dashData.syncInfo}</td>
                    </tr>
                  </tbody>
                </table>
              </span>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sync;
