import React, { useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { Toast } from "bootstrap";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

function ChangePhoneNo() {
  const dispatch = useDispatch();

  const modalEdit = useSelector((state) => state.dashboard.modalEdit);
  const modalID = useSelector((state) => state.dashboard.modalID);

  const [dashData, setDashData] = useState({
    disabled: false,
    status: false,
    PhoneNo: "",
    errPhoneNo: "",
    toastMSG: "",
    statusMSG: "",
  });

  function ModalClose(event) {
    dispatch(
      reducer.getModal({
        modalState: "",
      })
    );

    setDashData((prevDashData) => ({
      ...prevDashData,
      disabled: false,
      status: false,
      PhoneNo: "",
      errPhoneNo: "",
      toastMSG: "",
      statusMSG: "",
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*<>a-zA-Z])/;

    if (name === "PhoneNo") {
      let errMsg = dashData.errPlateNo;
      const orgInputValue = event.target.value.trim();
      const minLengthRegExp = /.{10,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "Phone Number is empty";
      } else if (orgLength > 10) {
        errMsg = "maximum 10 characters";
      } else if (specialCharorg) {
        errMsg = "Only numbers are allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 10 characters";
      } else {
        errMsg = "";
      }
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPhoneNo: errMsg,
      }));
    }

    setDashData((prevDashData) => ({
      ...prevDashData,
      [name]: value.toUpperCase(),
    }));
  }
  function ModalUpdate(params) {
    let err = 0;
    let errPhoneNo = dashData.errPhoneNo;
    let PhoneNo = dashData.PhoneNo;
    if (errPhoneNo) {
      err += 1;
    } else if (!PhoneNo) {
      err += 1;
      setDashData((prevDashData) => ({
        ...prevDashData,
        errPhoneNo: "Phone Number is empty",
      }));
    } else {
      err += 0;
    }
    if (err > 0) {
      let toastMSG = "Fix warning & Reupdate";
      let statusMSG = "Update";
      let status = true;
      let disabled = false;
      setDashData((prevDashData) => ({
        ...prevDashData,
        toastMSG: toastMSG,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));

      const toastLiveExample = document.getElementById("Toastmodal");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();
    } else {
      let toastMSG = "Wait it's Updating ...";
      let statusMSG = " Updating ...";
      let status = true;
      let disabled = true;
      setDashData((prevDashData) => ({
        ...prevDashData,
        statusMSG: statusMSG,
        toastMSG: toastMSG,
        status: status,
        disabled: disabled,
      }));
      const toastLiveExample = document.getElementById("Toastmodal");
      const toastShow = new Toast(toastLiveExample);
      toastShow.show();

      async function main() {
        const endpoint = env.SERVER_URL + "api/sms";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          NUMBER: dashData.PhoneNo,
          AUTOID: modalID,
        };
        const query = gql`
          mutation ($NUMBER: String!, $AUTOID: String!) {
            Update_PhoneNo(NUMBER: $NUMBER, AUTOID: $AUTOID) {
              AUTOID
              PLATE_ID
              NUMBER
              ONAME
              BRAND
              ExpireDay
              ExpireMonth
              ExpireYear
              C_P
              sms
              smscount
              Date_Sent
              Date_Expire
              CHASSIS_NO
              ENGINE_ID
            }
          }
        `;
        const data = await graphQLClient.request(query, variables);

        if (data["Update_PhoneNo"].length > 0) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            PhoneNo: data["Update_PhoneNo"][0]["NUMBER"],
            errPhoneNo: "",
            status: false,
            toastMSG: "Successfully",
            statusMSG: "Update",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
          dispatch(
            reducer.getCar({
              carInfo: data["Update_PhoneNo"][0],
            })
          );
        } else {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errPhoneNo: "",
            status: false,
            toastMSG: "Error",
            statusMSG: "Update",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      }
      main().catch((error) => {
        // console.error(error);
        let message = error.message.substring(0, 25);
        // let message2 = error.message.split('" ')[0];
        // console.log(message);
        if ("Network request failed" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            errPhoneNo: "",
            status: false,
            toastMSG: "NO INTERNET",
            disabled: false,
          }));
          const toastLiveExample = document.getElementById("liveToast");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }

        if ("GraphQL Error (Code: 409)" === message) {
          setDashData((prevDashData) => ({
            ...prevDashData,
            status: false,
            toastMSG: "Error",
            disabled: false,
          }));

          const toastLiveExample = document.getElementById("Toastmodal");
          const toastShow = new Toast(toastLiveExample);
          toastShow.show();
        }
      });
    }
  }
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Update Phone Number</h4>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={ModalClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="">
          <div className="row">
            <div className="col-sm-12">
              <label>Phone No.</label>
              <input
                className="form-control form-control-border border-width-2"
                type="text"
                id="PhoneNo"
                name="PhoneNo"
                onChange={handleChange}
                value={dashData.PhoneNo}
                autoComplete="PhoneNo"
                minLength="10"
                maxLength="10"
                placeholder={modalEdit}
                disabled={dashData.disabled}
                style={{
                  borderBottom: dashData.errPhoneNo && "3px solid red",
                }}
              />
              <span
                style={{
                  color: "rgb(243, 182, 51)",
                  margin: "0px 0px 30px 30px",
                }}
              >
                {dashData.errPhoneNo}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger "
          data-bs-dismiss="modal"
          onClick={ModalClose}
        >
          Close
        </button>
        <button
          type="button"
          disabled={dashData.disabled}
          className="btn btn-primary"
          onClick={ModalUpdate}
        >
          {dashData.disabled && (
            <span
              className="spinner-border text-light spinner-border-sm "
              role="status"
              aria-hidden="true"
            >
              {/* <ImSpinner9 className="sr-only" /> */}
            </span>
          )}
          <span>{dashData.status ? dashData.statusMSG : " Update"}</span>
        </button>
      </div>
      {/* toast-container */}
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div
          id="Toastmodal"
          className="toast align-items-center cs_toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">
              <h5>{dashData.toastMSG}</h5>
            </div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePhoneNo;
